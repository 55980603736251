<template>
  <a-row class="main-login">
    <a-col :span="12" style="height: 100%" :class="$project.hideRight ? 'login-main-box':''">
      <a-row class="main" type="flex" justify="center" align="middle">
        <a-row :gutter="[8, 8]" style="width: 100%">
          <a-col :span="24" style="text-align: center">
            <span class="login-header">欢迎登录{{ $project.systemName }}</span>
          </a-col>
        </a-row>
        <a-row :gutter="[8, 16]" style="width: 100%">
          <a-col :span="24" style="text-align: center">
            <a href="/">
              <img :src="$project.loginLogo" style="width: 42%" class="logo" alt="logo" />
            </a>
          </a-col>
        </a-row>
        <a-row :gutter="[8, 8]" style="width: 100%">
          <a-col :span="24">
            <div style="width: 60%; margin: auto"><a-divider /></div>
          </a-col>
        </a-row>
        <a-row :gutter="[8, 48]" type="flex" justify="center" style="width: 100%">
          <a-col :span="4"></a-col>
          <a-col :span="16">
            <a-form-model id="formLogin" ref="form" class="user-layout-login" :model="form" :rules="rules">
              <a-alert
                v-if="isLoginError"
                type="error"
                showIcon
                style="margin-bottom: 24px"
                :message="loginErrorInfo"
                closable
                :after-close="handleCloseLoginError"
              />
              <a-form-model-item prop="username">
                <a-input v-model="form.username" size="large" placeholder="账户">
                  <a-icon slot="prefix" type="user" :style="{ color: 'rgba(0,0,0,.25)' }" />
                </a-input>
              </a-form-model-item>
              <a-form-model-item prop="password">
                <a-input-password v-model="form.password" size="large" placeholder="密码">
                  <a-icon slot="prefix" type="lock" :style="{ color: 'rgba(0,0,0,.25)' }" />
                </a-input-password>
              </a-form-model-item>
              <a-row :gutter="16">
                <a-col class="gutter-row" :span="16">
                  <a-form-model-item prop="code">
                    <a-input v-model="form.code" size="large" type="text" autocomplete="off" placeholder="验证码">
                      <a-icon slot="prefix" type="security-scan" :style="{ color: 'rgba(0,0,0,.25)' }" />
                    </a-input>
                  </a-form-model-item>
                </a-col>
                <a-col class="gutter-row" :span="8">
                  <img class="getCaptcha" :src="codeUrl" @click="getCode" />
                </a-col>
              </a-row>
              <!-- <a-form-item>
                <a-checkbox v-decorator="['rememberMe', { valuePropName: 'checked' }]">记住密码</a-checkbox>
              </a-form-item> -->
              <a-form-item style="margin-top: 24px">
                <a-button
                  size="large"
                  type="primary"
                  htmlType="submit"
                  class="login-button"
                  :loading="logining"
                  :disabled="logining"
                  @click="handleSubmit"
                >确定</a-button
                >
              </a-form-item>
            </a-form-model>
          </a-col>
          <a-col :span="4"></a-col>
        </a-row>
      </a-row>
    </a-col>
    <a-col
      :span="12"
      style="height: 100%"
      v-if="!$project.hideRight"
      class="login-right"
      :style="rightBg ? `background-image: url(${$project.rightBg});` : ''"
    >
      <a-row type="flex" justify="space-around" align="middle" style="height: 100%">
        <a-col :span="24">
          <div class="logo-right">
            <a href="/">
              <!-- <img src="~@/assets/images/slogan.png" class="logo" alt="logo"> -->
              {{ $project.slogan }}
            </a>
          </div>
        </a-col>
      </a-row>
    </a-col>
  </a-row>
</template>

<script>
import { mapActions } from 'vuex'
import { timeFix } from '@/utils/util'
import { getCodeImg } from '@/api/login'
import { setDocumentTitle, domTitle } from '@/utils/domUtil'

export default {
  data() {
    return {
      codeUrl: '',
      isLoginError: false,
      loginErrorInfo: '',
      uniqueCode: '',
      form: {
        username: '',
        password: '',
        code: undefined,
        uuid: ''
      },
      rules: {
        username: [{ required: true, message: '请输入帐户名', trigger: 'blur' }],
        password: [{ required: true, message: '请输入密码', trigger: 'blur' }],
        code: [{ required: true, message: '请输入验证码', trigger: 'blur' }]
      },
      logining: false,
      customActiveKey: 'tab1',
      loginBtn: false,
      loginType: 0,
      requiredTwoStepCaptcha: false,
      stepCaptchaVisible: false,
      state: {
        time: 60,
        loginBtn: false,
        loginType: 0,
        smsSendBtn: false
      }
    }
  },
  created() {
    const hostname = location.hostname
    // console.log(`hostname========${ hostname }==length==${ hostname.split('.') }`)
    if (hostname.split('.').length === 4) {
      this.uniqueCode = hostname.split('.')[0]
      console.log(`uniqueCode========${this.uniqueCode}`)
    }
    this.getCode()
    setDocumentTitle(domTitle)
  },
  methods: {
    getCode() {
      getCodeImg().then((res) => {
        this.codeUrl = 'data:image/gif;base64,' + res.img
        this.form.uuid = res.uuid
      })
    },
    ...mapActions(['Login', 'Logout']),
    // handler
    handleSubmit() {
      this.logining = true
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.Login({
            ...this.form,
            uniqueCode: this.uniqueCode
          })
            .then((res) => this.loginSuccess(res))
            .catch((err) => this.requestFailed(err))
            .finally(() => {
              this.logining = false
            })
        } else {
          setTimeout(() => {
            this.logining = false
          }, 600)
        }
      })
    },
    loginSuccess(res) {
      this.$router.push({ path: '/' })
      // 延迟 1 秒显示欢迎信息
      setTimeout(() => {
        this.$notification.success({
          message: '欢迎',
          description: `${timeFix()}，欢迎回来`
        })
      }, 1000)
      this.handleCloseLoginError()
    },
    requestFailed(err) {
      this.isLoginError = true
      this.loginErrorInfo = err ? err.msg : '未知错误'
      this.form.code = undefined
      this.getCode()
    },
    handleCloseLoginError() {
      this.isLoginError = false
      this.loginErrorInfo = ''
    }
  }
}
</script>

<style lang="less">
.user-layout-login {
  label {
    font-size: 14px;
  }

  .getCaptcha {
    display: block;
    width: 100%;
    height: 40px;
  }

  button.login-button {
    padding: 0 15px;
    font-size: 16px;
    height: 40px;
    width: 100%;
  }
}
.main-login {
  margin: 0;
  height: 100%;
  width: 100%;
  .login-right {
    background-image: url(~@/assets/images/login-right-bg.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: inherit;
    background-position: center 0;
  }
  .main {
    min-width: 260px;
    width: 100%;
    height: 100%;
    background-color: #ffffff;
    padding: 20% 50px;
  }
  .ant-input {
    border-radius: 20px;
    background: #ffffff;
    border: 1px solid #d9d9d9;
    color: rgba(0, 0, 0, 0.85);
  }
  .has-error .ant-input-affix-wrapper .ant-input,
  .has-error .ant-input-affix-wrapper .ant-input:hover {
    background-color: #fff;
  }
  .ant-btn-lg {
    border-radius: 20px;
  }
  .login-header {
    width: 42%;
    padding-bottom: 1em;
    font-size: larger;
    font-weight: 800;
    color: #949494;
    img {
      height: auto;
      width: auto;
      max-height: 60%;
      max-width: 60%;
    }
  }
  .logo-right {
    width: 76%;
    margin: 1em auto 0px auto;
    text-align: center;
    a {
      color: #fff;
      font-size: xxx-large;
      font-weight: 900;
      text-shadow: 4px 3px 4px #013a07;
    }
    a:hover {
      color: #fff;
    }
    img {
      height: auto;
      width: auto;
      max-height: 100%;
      max-width: 100%;
    }
  }
  .user-layout-login {
    label {
      font-size: 14px;
    }

    .forge-password {
      font-size: 14px;
    }

    button.login-button {
      padding: 0 15px;
      font-size: 16px;
      height: 40px;
      width: 100%;
      background: #013a07;
      border: 1px solid #013a07;
      color: #ffffff;
    }

    .user-login-other {
      text-align: left;
      margin-top: 24px;
      line-height: 22px;

      .item-icon {
        font-size: 24px;
        color: rgba(0, 0, 0, 0.2);
        margin-left: 16px;
        vertical-align: middle;
        cursor: pointer;
        transition: color 0.3s;

        &:hover {
          color: #013a07;
        }
      }

      .register {
        float: right;
      }
    }
  }
  .login-main-box {
    transform: translateX(50%);
  }
}
</style>
