var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-row",
    { staticClass: "main-login" },
    [
      _c(
        "a-col",
        {
          class: _vm.$project.hideRight ? "login-main-box" : "",
          staticStyle: { height: "100%" },
          attrs: { span: 12 },
        },
        [
          _c(
            "a-row",
            {
              staticClass: "main",
              attrs: { type: "flex", justify: "center", align: "middle" },
            },
            [
              _c(
                "a-row",
                { staticStyle: { width: "100%" }, attrs: { gutter: [8, 8] } },
                [
                  _c(
                    "a-col",
                    {
                      staticStyle: { "text-align": "center" },
                      attrs: { span: 24 },
                    },
                    [
                      _c("span", { staticClass: "login-header" }, [
                        _vm._v("欢迎登录" + _vm._s(_vm.$project.systemName)),
                      ]),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "a-row",
                { staticStyle: { width: "100%" }, attrs: { gutter: [8, 16] } },
                [
                  _c(
                    "a-col",
                    {
                      staticStyle: { "text-align": "center" },
                      attrs: { span: 24 },
                    },
                    [
                      _c("a", { attrs: { href: "/" } }, [
                        _c("img", {
                          staticClass: "logo",
                          staticStyle: { width: "42%" },
                          attrs: { src: _vm.$project.loginLogo, alt: "logo" },
                        }),
                      ]),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "a-row",
                { staticStyle: { width: "100%" }, attrs: { gutter: [8, 8] } },
                [
                  _c("a-col", { attrs: { span: 24 } }, [
                    _c(
                      "div",
                      { staticStyle: { width: "60%", margin: "auto" } },
                      [_c("a-divider")],
                      1
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "a-row",
                {
                  staticStyle: { width: "100%" },
                  attrs: { gutter: [8, 48], type: "flex", justify: "center" },
                },
                [
                  _c("a-col", { attrs: { span: 4 } }),
                  _c(
                    "a-col",
                    { attrs: { span: 16 } },
                    [
                      _c(
                        "a-form-model",
                        {
                          ref: "form",
                          staticClass: "user-layout-login",
                          attrs: {
                            id: "formLogin",
                            model: _vm.form,
                            rules: _vm.rules,
                          },
                        },
                        [
                          _vm.isLoginError
                            ? _c("a-alert", {
                                staticStyle: { "margin-bottom": "24px" },
                                attrs: {
                                  type: "error",
                                  showIcon: "",
                                  message: _vm.loginErrorInfo,
                                  closable: "",
                                  "after-close": _vm.handleCloseLoginError,
                                },
                              })
                            : _vm._e(),
                          _c(
                            "a-form-model-item",
                            { attrs: { prop: "username" } },
                            [
                              _c(
                                "a-input",
                                {
                                  attrs: { size: "large", placeholder: "账户" },
                                  model: {
                                    value: _vm.form.username,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "username", $$v)
                                    },
                                    expression: "form.username",
                                  },
                                },
                                [
                                  _c("a-icon", {
                                    style: { color: "rgba(0,0,0,.25)" },
                                    attrs: { slot: "prefix", type: "user" },
                                    slot: "prefix",
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "a-form-model-item",
                            { attrs: { prop: "password" } },
                            [
                              _c(
                                "a-input-password",
                                {
                                  attrs: { size: "large", placeholder: "密码" },
                                  model: {
                                    value: _vm.form.password,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "password", $$v)
                                    },
                                    expression: "form.password",
                                  },
                                },
                                [
                                  _c("a-icon", {
                                    style: { color: "rgba(0,0,0,.25)" },
                                    attrs: { slot: "prefix", type: "lock" },
                                    slot: "prefix",
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "a-row",
                            { attrs: { gutter: 16 } },
                            [
                              _c(
                                "a-col",
                                {
                                  staticClass: "gutter-row",
                                  attrs: { span: 16 },
                                },
                                [
                                  _c(
                                    "a-form-model-item",
                                    { attrs: { prop: "code" } },
                                    [
                                      _c(
                                        "a-input",
                                        {
                                          attrs: {
                                            size: "large",
                                            type: "text",
                                            autocomplete: "off",
                                            placeholder: "验证码",
                                          },
                                          model: {
                                            value: _vm.form.code,
                                            callback: function ($$v) {
                                              _vm.$set(_vm.form, "code", $$v)
                                            },
                                            expression: "form.code",
                                          },
                                        },
                                        [
                                          _c("a-icon", {
                                            style: { color: "rgba(0,0,0,.25)" },
                                            attrs: {
                                              slot: "prefix",
                                              type: "security-scan",
                                            },
                                            slot: "prefix",
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "a-col",
                                {
                                  staticClass: "gutter-row",
                                  attrs: { span: 8 },
                                },
                                [
                                  _c("img", {
                                    staticClass: "getCaptcha",
                                    attrs: { src: _vm.codeUrl },
                                    on: { click: _vm.getCode },
                                  }),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "a-form-item",
                            { staticStyle: { "margin-top": "24px" } },
                            [
                              _c(
                                "a-button",
                                {
                                  staticClass: "login-button",
                                  attrs: {
                                    size: "large",
                                    type: "primary",
                                    htmlType: "submit",
                                    loading: _vm.logining,
                                    disabled: _vm.logining,
                                  },
                                  on: { click: _vm.handleSubmit },
                                },
                                [_vm._v("确定")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("a-col", { attrs: { span: 4 } }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      !_vm.$project.hideRight
        ? _c(
            "a-col",
            {
              staticClass: "login-right",
              staticStyle: { height: "100%" },
              style: _vm.rightBg
                ? "background-image: url(" + _vm.$project.rightBg + ");"
                : "",
              attrs: { span: 12 },
            },
            [
              _c(
                "a-row",
                {
                  staticStyle: { height: "100%" },
                  attrs: {
                    type: "flex",
                    justify: "space-around",
                    align: "middle",
                  },
                },
                [
                  _c("a-col", { attrs: { span: 24 } }, [
                    _c("div", { staticClass: "logo-right" }, [
                      _c("a", { attrs: { href: "/" } }, [
                        _vm._v(" " + _vm._s(_vm.$project.slogan) + " "),
                      ]),
                    ]),
                  ]),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }